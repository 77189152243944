import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { TREINAMENTO_PREFIX_PATH } from "configs/AppConfig";
import TemPermissao from "components/atom/TemPermissao";
import Permissoes from "enum/permissao-enum";

export const TreinamentoViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<TemPermissao permissao={Permissoes.TREINAMENTOS.VISUALIZAR}>
				<Switch>
					<Route
						path={`${TREINAMENTO_PREFIX_PATH}/dashboard`}
						component={lazy(() => import(`./dashboard`))}
					/>
					<Route
						path={`${TREINAMENTO_PREFIX_PATH}/modulos/:id`}
						component={lazy(() => import(`./modulos`))}
					/>
					<Route
						path={`${TREINAMENTO_PREFIX_PATH}/modulos`}
						component={lazy(() => import(`./modulos`))}
					/>
					<Redirect
						from={`${TREINAMENTO_PREFIX_PATH}`}
						to={`${TREINAMENTO_PREFIX_PATH}/dashboard`}
					/>
				</Switch>
			</TemPermissao>
		</Suspense>
	);
};

export default TreinamentoViews;
