import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN, AUTH_TOKEN_FRANQUEADORA } from "redux/constants/Auth";
import { notification } from "antd";
// import store from 'redux/store';
import { ID_UNIDADE } from "redux/constants/User";
// import {SET_TOKEN_EXPIRED} from 'redux/constants/Gui';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
});

// Config
const ENTRY_ROUTE = "#/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const TOKEN_PAYLOAD_KEY_FRANQUEADORA = "authorization_franqueadora";
const UNIDADE_ID = "id_unidade";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
	(config) => {
		const jwtToken = config.token ?? localStorage.getItem(AUTH_TOKEN);
		const jwtTokenFranqueadora =
			config.tokenFranqueadora ??
			localStorage.getItem(AUTH_TOKEN_FRANQUEADORA);
		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
		}

		if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		if (jwtTokenFranqueadora) {
			config.headers[TOKEN_PAYLOAD_KEY_FRANQUEADORA] =
				jwtTokenFranqueadora;
		}

		const unidade = localStorage.getItem(ID_UNIDADE);

		if (unidade) {
			config.headers[UNIDADE_ID] = unidade;
		} else {
			config.headers[UNIDADE_ID] = undefined;
		}

		return config;
	},
	(error) => {
		// Do something with request error here
		notification.error({
			message: "Error",
		});
		Promise.reject(error);
	},
);

// API respone interceptor
service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		let notificationParam = {
			message: error.response.data.message || "Error",
		};

		if (
			!error.response &&
			(!window.location.url.includes("error/403") ||
				!window.location.url.includes("error/423"))
		) {
			notificationParam.message = "Erro inesperado";
			notificationParam.description = error.message;
			localStorage.removeItem(AUTH_TOKEN);
			notification.error(notificationParam);
			return Promise.reject(error);
		}

		// Remove token and redirect
		if (error.response.status === 400) {
			notificationParam.message = "Authentication Fail";
			notificationParam.description = "Please login again";
			localStorage.removeItem(AUTH_TOKEN);
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		if (error.response.status === 401) {
			notificationParam.message = "Authentication Fail";
			if (error.response.data.franqueadora) {
				localStorage.removeItem(AUTH_TOKEN_FRANQUEADORA);
				window.location.reload();
			}
			localStorage.removeItem(AUTH_TOKEN);
			history.push(ENTRY_ROUTE);
			window.location.reload();
			// store.dispatch({
			// 	type: SET_TOKEN_EXPIRED,
			// 	isExpired: true,
			// });
		}

		if (error.response.status === 402) {
			notificationParam.message =
				error.response.data.message ?? "Recurso não encontrado";
			history.push("#/app/error/403");
			window.location.reload();
		}

		if (error.response.status === 403) {
			notificationParam.message =
				error.response.data.message ?? "Recurso não encontrado";
			history.push("#/app/error/404");
		}

		if (error.response.status === 404) {
			history.push("#/app/error/404");
			window.location.reload();
		}

		if (error.response.status === 423) {
			notificationParam.message =
				error.response.data.message ?? "Recurso não encontrado";
			history.push("#/app/error/423");
			window.location.reload();
		}

		if (error.response.status === 500) {
			notificationParam.message =
				error.response.data.message ?? "Internal Server Error";
			if (
				error.response.data.message === "Failed to authenticate token."
			) {
				localStorage.removeItem(AUTH_TOKEN);
				history.push(ENTRY_ROUTE);
				window.location.reload();
			}
		}

		if (error.response.status === 409) {
			notificationParam.message =
				error.response.data.message ?? "Recurso com conflito";
		}

		if (error.response.status === 508) {
			notificationParam.message = "Time Out";
		}

		if (error.response.status === 406) {
			notificationParam.message =
				error.response.data.message ?? "Recurso não encontrado";
		}

		notification.error({
			...notificationParam,
			duration: 3,
		});
		return Promise.reject(error);
	},
);

export default service;
