const Permissoes = {};

Permissoes.INTELIGENCIA = {
	RANKING: 1,
	RESUMOS: 2,
	RELATORIO_ORCAMENTOS: 153,
	GRAFICO_DESPESA_POR_CATEGORIA: 186,
	GRAFICO_COMO_O_PACIENTE_CHEGOU: 187,
	RANKING_MULTIPLICADORA: 211,
	MEDIA_AVALIACOES: 213,
	RANKING_INDICACOES: 215,
	FILTRO_RANKING: 223,
	RELATORIO_DESPESAS: 229,
	RELATORIO_APP: 242,
	RELATORIO_AGENDAMENTOS: 243,
};

Permissoes.AGENDA = {
	CRIAR: 3,
	EDITAR: 4,
	CRIAR_COMPROMISSO: 5,
	VISUALIZAR: 6,
	ESCOLHER_DENTISTA: 7,
	VIZUALIZAR_VALOR_TRATAMENTO: 156,
	EXCLUIR: 195,
};

Permissoes.PACIENTES = {
	CRIAR: 8,
	EDITAR: 9,
	VISUALIZAR_LISTA: 10,
	VISUALIZAR_PACIENTE: 11,
	BUSCAR: 12,
	SITUACAO: 13,
	AVALIACAO: 14,
	ORIGEM: 15,
	CAMPANHA: 16,
	DATA_RETORNO_CONTATO: 17,
	SOBRE: 18,
	ORCAMENTO: 19,
	TRTAMENTOS: 20,
	ANAMNESE: 21,
	IMAGENS: 22,
	DOCUMENTOS: 23,
	DEBITOS: 24,
	CRIAR_AVALIACAO: 25,
	CRIAR_ORCAMENTO: 26,
	APROVAR_ORCAMENTO: 27,
	EDITAR_ORCAMENTO: 28,
	EXCLUIR_ORCAMENTO: 29,
	REVERTER_ORCAMENTO: 30,
	IMPRIMIR_ORCAMENTO: 31,
	ADICIONAR_EVOLUCAO: 32,
	EDITAR_EVOLUCAO: 33,
	EXCLUIR_EVOLUCAO: 34,
	VISUALIZAR_EVOLUCAO: 35,
	VISUALIZAR_OBSERVACOES_TRATAMENTO: 36,
	FINALIZAR_TRATAMENTO: 37,
	AGENDAR_TRATAMENTO: 38,
	EDITAR_ANAMNESE_RESPOSTA: 39,
	ADICIONAR_IMAGENS: 40,
	EXCLUIR_IMAGENS: 41,
	ADICIONAR_DOCUMENTO: 42,
	EXCLUIR_DOCUMENTO: 43,
	RECEBER_DEBITOS: 44,
	GERAR_BOLETO: 45,
	EDITAR_DEBITOS: 46,
	EXCLUIR_DEBITOS: 47,
	PARCELAR_DEBITOS: 48,
	IMPRIMIR_DEBITOS: 49,
	VISUALIZAR_INFORMACOES_DEBITOS: 50,
	HISTORICO_TELEFONE: 167,
	REVERTER_PAGAMENTO: 189,
	LANCAR_PAGAMENTO_RETROATIVO: 190,
	VISUALIZAR_CONSULTAS: 196,
	VISUALIZAR_DADOS_GERAIS: 197,
	EXCLUIR: 206,
	APROVAR_ORCAMENTO_RETROATIVO: 212,
	RESETAR_SENHA: 237,
};

Permissoes.FINANCEIRO = {
	FLUXO_CAIXA: 51,
	COMISSOES: 52,
	METAS: 53,
	INADIMPLENTES: 54,
	BOLETOS: 55,
	VISUALIZAR_FATURAMENTO_BRUTO: 56,
	VISUALIZAR_RESUMO_CAIXA_RECEITAS: 57,
	VISUALIZAR_RESUMO_CAIXA_DESPESAS: 58,
	VISUALIZAR_RESUMO_CAIXA_SALDO: 59,
	VISUALIZAR_RECEITAS: 60,
	VISUALIZAR_DESPESAS: 61,
	VISUALIZAR_RECEITAS_DESPESAS: 62,
	CRIAR_NOVA_DESPESA: 62,
	ADIANTAR_DESPESA: 64,
	VISUALIZAR_DESPESA: 65,
	EXCLUIR_DESPESA: 66,
	PAGAR_DESPESA: 67,
	ADIANTAR_RECEITA: 68,
	RECEBER_RECEITA: 69,
	VISUALIZAR_DETALHES_COMISSAO: 70,
	PAGAR_COMISSAO: 71,
	EXCLUIR_COMISSAO: 72,
	TRANSFERIR_SALDO_BOLETOS: 73,
	EDITAR_DESPESA: 154,
	EDITAR_CAIXA_FECHADO: 165,
	EXPORTAR_DESPESAS: 169,
	EXPORTAR_RECEITAS: 170,
	EDITAR_CATEGORIA_DESPESA_PAGA: 222,
	PAGAR_RETROATIVO_UM_DIA: 235,
	PAGAR_RETROATIVO_TRES_DIAS: 240,
	EDITAR_DESPESA_PAGA: 248,
	PAGAR_RETROATIVO_QUINZE_DIAS: 249,
};

Permissoes.ESTOQUE = {
	CADASTRAR: 74,
	EDITAR: 75,
	EXCLUIR: 76,
	RETIRAR: 77,
	QUANTIDADE: 78,
};

Permissoes.PLANOS = {
	CRIAR: 79,
	EDITAR: 80,
	DESATIVAR: 81,
	EXCLUIR: 238,
	VISUALIZAR_TRATAMENTOS: 82,
	CRIAR_TRATAMENTO: 83,
	EDITAR_TRATAMENTO: 84,
	DESATIVAR_TRATAMENTO: 85,
	IMPORTAR_PLANO_PADRAO: 86,
	EXCLUIR_TRATAMENTO: 239,
};

Permissoes.ESPECIALIDADES = {
	CRIAR: 87,
	EDITAR: 88,
	DESATIVAR: 89,
};

Permissoes.USUARIOS = {
	CRIAR: 90,
	EDITAR: 91,
	DESATIVAR: 92,
	INTEGRAR_ALUNO: 93,
	SELECIONAR_UNIDADE: 155,
};

Permissoes.UNIDADE = {
	CRIAR: 94,
	EDITAR: 95,
	BLOQUEAR: 157,
};

Permissoes.EMPRESA = {
	CRIAR: 96,
	EDITAR: 97,
	DESATIVAR: 98,
};

Permissoes.ANAMNESE = {
	CRIAR: 99,
	EDITAR: 100,
};

Permissoes.INDICACOES = {
	CRIAR: 101,
	EDITAR: 102,
};

Permissoes.FORNECEDORES = {
	CRIAR: 103,
	EDITAR: 104,
};

Permissoes.PERMISSOES = {
	CRIAR: 105,
	EDITAR: 106,
	EXCLUIR: 107,
};

Permissoes.FORMA_PAGAMENTO = {
	CRIAR: 108,
	EDITAR: 109,
	DESATIVAR: 110,
};

Permissoes.CATEGORIA_DESPESA = {
	CRIAR: 111,
	EDITAR: 112,
	DESATIVAR: 113,
};

Permissoes.SUBCATEGORIA_DESPESA = {
	CRIAR: 114,
	EDITAR: 115,
	DESATIVAR: 116,
};

Permissoes.TIPO_DESPESA = {
	CRIAR: 117,
	EDITAR: 118,
	DESATIVAR: 119,
};

Permissoes.CONTA_DESPESA = {
	CRIAR: 120,
	EDITAR: 121,
	DESATIVAR: 122,
};

Permissoes.METAS = {
	CRIAR: 123,
	EDITAR: 124,
	EXCLUIR: 125,
	PAGAR_BONIFICACAO_META: 171,
};

Permissoes.BONIFICACOES = {
	CRIAR: 201,
	EDITAR: 202,
	EXCLUIR: 203,
	PAGAR: 205,
};

Permissoes.MENU_LATERAL = {
	INTELIGENCIA: 126,
	AGENDA: 127,
	PACIENTES: 128,
	FINANCEIRO: 129,
	ESTOQUE: 130,
	PLANOS: 131,
	PACIENTES_POR_PLANO: 132,
	ESPECIALIDADES: 133,
	USUARIOS: 134,
	UNIDADE: 135,
	EMPRESA: 136,
	ANAMNESE: 137,
	INDICACOES: 138,
	REGISTROS: 139,
	FORNECEDORES: 140,
	PERMISSOES: 141,
	FORMA_PAGAMENTO: 142,
	CATEGORIA_DESPESA: 143,
	SUBCATEGORIA_DESPESA: 144,
	TIPO_DESPESA: 145,
	CONTA_DESPESA: 146,
	METAS: 147,
	AULAS: 148,
	ROTULO: 149,
	TREINAMENTOS: 162,
	WHATSAPP: 176,
	GERENCIAMENTO_WHATSAPP: 177,
	LEADS: 178,
	RELATORIOS: 181,
	AGENCIA: 191,
	BONIFICACOES: 204,
	CHAT: 198,
	AVALIACOES_UNIDADE: 214,
	CAMPANHAS: 216,
	PRODUTO_ECOMMERCE: 228,
	OUVIDORIA: 230,
	ASSINAR_APLICATIVO: 236,
};

Permissoes.ROTULO = {
	CRIAR: 150,
	EDITAR: 151,
	EXCLUIR: 152,
};

Permissoes.TREINAMENTOS = {
	CRIAR: 158,
	EDITAR: 159,
	VISUALIZAR: 161,
};

Permissoes.DRE = {
	VIZUALIZAR_COMPARATIVO: 163,
	VIZUALIZAR_DRE: 164,
};

Permissoes.WHATSAPP = {
	CRIAR_NUMERO: 172,
	EDITAR_NUMERO: 173,
	EXCLUIR_NUMERO: 174,
	AUTENTICAR_NUMERO: 175,
	CRIAR_MENSAGEM: 182,
	EDITAR_MENSAGEM: 183,
	EXCLUIR_MENSAGEM: 184,
	ENVIAR_MENSAGEM: 185,
};

Permissoes.LEADS = {
	CRIAR: 179,
	EDITAR: 180,
	ADMINISTRADOR: 247,
};

Permissoes.FATURAS = {
	VISUALIZAR: 188,
};

Permissoes.AGENCIA = {
	CRIAR: 192,
	EDITAR: 193,
	EXCLUIR: 194,
};

Permissoes.CHAT = {
	VISUALIZAR: 199,
	ENVIAR_MENSAGEM: 200,
};

Permissoes.CAMPANHA = {
	VISUALIZAR: 217,
	ENVIAR_MENSAGEM: 218,
	CRIAR: 219,
	EDITAR: 220,
	EXCLUIR: 221,
	CRIAR_CARGA_LEADS: 241,
};

Permissoes.PRODUTO_ECOMMERCE = {
	VISUALIZAR: 224,
	CRIAR: 225,
	EDITAR: 226,
	EXCLUIR: 227,
};

Permissoes.OUVIDORIA = {
	VISUALIZAR_TODAS: 231,
	EDITAR_STATUS: 232,
	ADICIONAR_COMENTARIO: 233,
	REMOVER_COMENTARIO: 234,
};

module.exports = Permissoes;
