// TODO: após clonar o skeleton, ajustar os links de ambientes

const dev = {
	API_ENDPOINT_URL: "http://localhost:8989/oral-brasil/api",
	BOTZAP_SOCKET_ENDPOINT_URL: "http://localhost:8081/",
	BOTZAP_SOCKET_PATH: "/socket.io",
	PUBLIC_PREFIX_PATH: "",
	API_SOCKET_ENDPOINT_URL: "http://localhost:8989/",
	API_SOCKET_SUFIX_PATH: "/socket.io",
};

const prod = {
	API_ENDPOINT_URL:
		"https://api.clinicasoralbrasil.com.br/nodeoralbrasil2/oral-brasil/api",
	BOTZAP_SOCKET_ENDPOINT_URL: "https://apolo.dimo.com.br",
	BOTZAP_SOCKET_PATH: "/botzap/socket.io",
	PUBLIC_PREFIX_PATH: "",
	API_SOCKET_ENDPOINT_URL: "https://api.clinicasoralbrasil.com.br/",
	API_SOCKET_SUFIX_PATH: "/nodeoralbrasil2/oral-brasil/socket.io",
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/nodeoralbrasil/api",
	BOTZAP_SOCKET_ENDPOINT_URL: "https://homolog.dimo.com.br",
	BOTZAP_SOCKET_PATH: "/botzap/socket.io",
	PUBLIC_PREFIX_PATH: "/oralbrasil",
	API_SOCKET_ENDPOINT_URL: "https://homolog.dimo.com.br/",
	API_SOCKET_SUFIX_PATH: "/nodeoralbrasil/socket.io",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			break;
	}
};

export const env = getEnv();
