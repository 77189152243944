import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import User from "./User";
import Gui from "./Gui";
import Permissoes from "./Permissoes";
import MenuTreinamento from "./MenuTreinamento";
import Notificacoes from "./Notificacoes";
import Data from "./Data";
import Menu from "./Menu";

const reducers = combineReducers({
	theme: Theme,
	auth: Auth,
	user: User,
	gui: Gui,
	permissoes: Permissoes,
	menuTreinamento: MenuTreinamento,
	notificacoes: Notificacoes,
	data: Data,
	menu: Menu,
});

export default reducers;
