import fetch from "auth/FetchInterceptor";

const notificacaoService = {};

notificacaoService.visualizar = async function (values) {
	return fetch({
		url: "/v1/rest/visualizar-notificacoes",
		method: "post",
		data: values,
	});
};

export default notificacaoService;
