import React, { useEffect } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import {
	SIDE_NAV_WIDTH,
	SIDE_NAV_DARK,
	NAV_TYPE_SIDE,
} from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";
import { toggleCollapsedNav } from "../../redux/actions/Theme";
const { Sider } = Layout;

export const SideNav = ({
	navCollapsed,
	sideNavTheme,
	routeInfo,
	hideGroupTitle,
	navigationConfig,
	toggleCollapsedNav,
	localization = true,
	isMobile,
}) => {
	const props = {
		navigationConfig,
		sideNavTheme,
		routeInfo,
		hideGroupTitle,
		localization,
		isMobile,
	};

	useEffect(() => {
		toggleCollapsedNav(isMobile);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile]);

	return (
		<Sider
			className={`side-nav ${
				sideNavTheme === SIDE_NAV_DARK ? "side-nav-dark" : ""
			}`}
			width={SIDE_NAV_WIDTH}
			collapsed={navCollapsed}
		>
			<Scrollbars autoHide>
				<MenuContent type={NAV_TYPE_SIDE} {...props} />
			</Scrollbars>
		</Sider>
	);
};

const mapStateToProps = ({ theme }) => {
	const { navCollapsed, sideNavTheme } = theme;
	return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(SideNav);
