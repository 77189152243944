import { API_BASE_URL } from "configs/AppConfig";

const redirectService = {};

redirectService.get = async function (hash) {
	const request = await fetch(`${API_BASE_URL}/v1/rpc/link/${hash}`, {
		method: "get",
	});

	return await request.json();
};

export default redirectService;
