import React from "react";
import { Spin } from "antd";
import { PUBLIC_PREFIX_PATH } from "configs/AppConfig";
/*
  Átomo de Loading. Mostra o spinner quando isLoading = true.
  Em geral, deve englobar todos os componentes da tela.
*/
export default function Loading(props) {
	const { isLoading, children } = props;
	const icon = (
		<div>
			<img
				alt="loading"
				src={`${PUBLIC_PREFIX_PATH}/oralbrasil_loading.gif`}
				style={{
					width: 70,
					top: "50%",
					transform: "translateY(-50%)",
					position: "relative",
					filter: "brightness(0) saturate(100%) invert(15%) sepia(54%) saturate(2966%) hue-rotate(204deg) brightness(92%) contrast(98%)",
				}}
			/>
		</div>
	);
	return (
		<Spin
			size="large"
			spinning={isLoading}
			style={{ position: "absolute" }}
			indicator={icon}
		>
			{children}
		</Spin>
	);
}
