import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH, PUBLIC_PREFIX_PATH } from "configs/AppConfig";
import { notification } from "antd";
import { chatSocket } from "services/SocketService";
import MenuService from "services/MenuService";
import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

export const AppViews = ({ token }) => {
	useEffect(() => {
		const onNovaMensagem = async (value) => {
			if (window.location.hash !== "#/app/chat") {
				let audio = new Audio(
					`${PUBLIC_PREFIX_PATH}/audio/notification.wav`,
				);
				audio.play();
				notification.open({
					message: value.nome,
					description:
						value.tipo === 1
							? value.conteudo
							: value.tipo === 2
							? "Nova imagem"
							: "Novo áudio",
					icon: <UserOutlined />,
				});
				MenuService.increaseNotifications("mensagensNaoLidas");
			}
		};

		chatSocket.on("nova_mensagem_menu", onNovaMensagem);
		return () => {
			chatSocket.off("nova_mensagem_menu");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (token) {
			chatSocket.emit("login", token);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, chatSocket]);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/inteligencia`}
					component={lazy(() => import(`./inteligencia`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agenda/:idprofissional/:datainicial`}
					component={lazy(() => import(`./agenda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agenda/:idprofissional`}
					component={lazy(() => import(`./agenda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agenda`}
					component={lazy(() => import(`./agenda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relacao-de-orcamentos`}
					component={lazy(() => import(`./relacao-de-orcamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agendamento/:id/:data/:id_cliente/:id_tratamento`}
					component={lazy(() => import(`./agendamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agendamento/:id/:data`}
					component={lazy(() => import(`./agendamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agendamento/:id`}
					component={lazy(() => import(`./agendamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agendamento`}
					component={lazy(() => import(`./agendamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-pacientes`}
					component={lazy(() => import(`./lista-pacientes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cliente/:id`}
					component={lazy(() => import(`./cliente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/paciente/:id`}
					component={lazy(() => import(`./paciente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/paciente`}
					component={lazy(() => import(`./paciente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-estoque`}
					component={lazy(() => import(`./lista-estoque`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/estoque/:id`}
					component={lazy(() => import(`./estoque`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/estoque`}
					component={lazy(() => import(`./estoque`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/vendas`}
					component={lazy(() => import(`./vendas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/ouvidoria`}
					component={lazy(() => import(`./ouvidoria`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/assinar-aplicativo`}
					component={lazy(() => import(`./assinar-aplicativo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro/:id`}
					component={lazy(() => import(`./financeiro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/financeiro`}
					component={lazy(() => import(`./financeiro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/campanhas`}
					component={lazy(() => import(`./campanhas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/campanha/:id`}
					component={lazy(() =>
						import(`./campanhas/visualizar-campanha`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/criar-campanha`}
					component={lazy(() => import(`./campanhas/criar-campanha`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/editar-campanha/:id`}
					component={lazy(() => import(`./campanhas/criar-campanha`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/retirar-produto-estoque/:id/:produto/:quantidade`}
					component={lazy(() => import(`./retirar-produto-estoque`))}
				/>
				{/* <Route path={`${APP_PREFIX_PATH}/chat-mensagens/:id`} component={lazy(() => import(`./chat`))} /> */}
				<Route
					path={`${APP_PREFIX_PATH}/chat`}
					component={lazy(() => import(`./chat`))}
				/>
				{/* <Route path={`${APP_PREFIX_PATH}/whatsapp/:id`} component={lazy(() => import(`./whatsapp/chat`))} />
				<Route
					path={`${APP_PREFIX_PATH}/whatsapp`}
					component={lazy(() => import(`./whatsapp/selecao-numero`))}
				/> */}
				<Route
					path={`${APP_PREFIX_PATH}/avaliacao`}
					component={lazy(() => import(`./avaliacao`))}
				/>

				{/*Fluxo de Cadastros Secundários*/}
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-planos`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-planos`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/plano/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/plano`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/plano`}
					component={lazy(() =>
						import(`./cadastros-secundarios/plano`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-clientes-planos`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-clientes-planos`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-clientes-por-plano/:id`}
					component={lazy(() =>
						import(
							`./cadastros-secundarios/lista-clientes-por-plano`
						),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-produtos-ecommerce`}
					component={lazy(() =>
						import(
							`./cadastros-secundarios/lista-produtos-ecommerce`
						),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/produto-ecommerce/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/produto-ecommerce`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/produto-ecommerce`}
					component={lazy(() =>
						import(`./cadastros-secundarios/produto-ecommerce`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-especialidades`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-especialidades`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/especialidade/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/especialidade`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/especialidade`}
					component={lazy(() =>
						import(`./cadastros-secundarios/especialidade`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-usuarios`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-usuarios`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/usuario/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/usuario`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/usuario`}
					component={lazy(() =>
						import(`./cadastros-secundarios/usuario`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-unidades`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-unidades`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/unidade/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/unidade`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/unidade`}
					component={lazy(() =>
						import(`./cadastros-secundarios/unidade`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-empresas`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-empresas`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/agencia/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/agencia`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/agencia`}
					component={lazy(() =>
						import(`./cadastros-secundarios/agencia`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-agencia`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-agencia`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/empresa/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/empresa`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/empresa`}
					component={lazy(() =>
						import(`./cadastros-secundarios/empresa`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-anamneses`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-anamneses`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/anamnese/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/anamnese`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/anamnese`}
					component={lazy(() =>
						import(`./cadastros-secundarios/anamnese`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-indicacoes`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-indicacoes`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/indicacao/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/indicacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/indicacao`}
					component={lazy(() =>
						import(`./cadastros-secundarios/indicacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-registros`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-registros`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-tratamentos/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-tratamentos`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/tratamento/:idplano/:idtratamento`}
					component={lazy(() =>
						import(`./cadastros-secundarios/tratamento`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/tratamento/:idplano`}
					component={lazy(() =>
						import(`./cadastros-secundarios/tratamento`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-fornecedores`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-fornecedores`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/fornecedor/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/fornecedor`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/fornecedor`}
					component={lazy(() =>
						import(`./cadastros-secundarios/fornecedor`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-rotulos`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-rotulos`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/rotulo/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/rotulo`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/rotulo`}
					component={lazy(() =>
						import(`./cadastros-secundarios/rotulo`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-contas-despesas`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-contas-despesas`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/conta-despesa/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/conta-despesa`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/conta-despesa`}
					component={lazy(() =>
						import(`./cadastros-secundarios/conta-despesa`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-metas`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-metas`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/meta/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/meta`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/meta`}
					component={lazy(() =>
						import(`./cadastros-secundarios/meta`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/lista-bonificacoes`}
					component={lazy(() =>
						import(`./cadastros-secundarios/lista-bonificacoes`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/bonificacao/:id`}
					component={lazy(() =>
						import(`./cadastros-secundarios/bonificacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastros-secundarios/bonificacao`}
					component={lazy(() =>
						import(`./cadastros-secundarios/bonificacao`),
					)}
				/>

				{/*Fluxo de Configurações*/}
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-formas-pagamento`}
					component={lazy(() =>
						import(`./configuracoes/lista-formas-pagamento`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/forma-pagamento/:id`}
					component={lazy(() =>
						import(`./configuracoes/forma-pagamento`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/forma-pagamento`}
					component={lazy(() =>
						import(`./configuracoes/forma-pagamento`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/lista-permissoes`}
					component={lazy(() =>
						import(`./configuracoes/lista-permissoes`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/permissao/:id`}
					component={lazy(() => import(`./configuracoes/permissao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/permissao`}
					component={lazy(() => import(`./configuracoes/permissao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes/whatsapp`}
					component={lazy(() => import(`./configuracoes/whatsapp`))}
				/>

				{/*Fluxo de Aulas*/}
				<Route
					path={`${APP_PREFIX_PATH}/aulas/dashboard`}
					component={lazy(() => import(`./aulas/dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/aulas/marketing`}
					component={lazy(() => import(`./aulas/marketing`))}
				/>

				{/* Relatórios */}
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/orcamentos`}
					component={lazy(() => import(`./relatorios/orcamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/cliente-app`}
					component={lazy(() => import(`./relatorios/cliente-app`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/agendamentos`}
					component={lazy(() => import(`./relatorios/agendamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/media-fechamento`}
					component={lazy(() =>
						import(`./relatorios/media-fechamento`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/ticket-medio-usuarios`}
					component={lazy(() =>
						import(`./relatorios/ticket-medio-usuarios`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/despesas`}
					component={lazy(() => import(`./relatorios/despesas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/ticket-medio-receitas`}
					component={lazy(() =>
						import(`./relatorios/ticket-medio-receitas`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/especialidades-feitas`}
					component={lazy(() =>
						import(`./relatorios/especialidades-feitas`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/vendas`}
					component={lazy(() => import(`./relatorios/vendas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/pagamentos`}
					component={lazy(() => import(`./relatorios/pagamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/dre-unidade`}
					component={lazy(() => import(`./relatorios/dre-unidade`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorios/dre-geral`}
					component={lazy(() => import(`./relatorios/dre-geral`))}
				/>

				{/*treinamentos*/}
				<Route
					path={`${APP_PREFIX_PATH}/treinamentos/`}
					component={lazy(() => import(`./treinamentos`))}
				/>

				{/* Páginas de erro */}
				<Route
					path={`${APP_PREFIX_PATH}/error/404`}
					component={lazy(() => import(`./error/404`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/error/423`}
					component={lazy(() => import(`./error/423`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/error/403`}
					component={lazy(() => import(`./error/403`))}
				/>

				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/inteligencia`}
				/>
			</Switch>
		</Suspense>
	);
};

const mapStateToProps = ({ user, auth }) => {
	return { user: user, token: auth.token };
};

export default connect(mapStateToProps)(React.memo(AppViews));
