import { UPDATE_NOTIFICACOES } from "../constants/Notificacoes";

const initNotificacoes = { notificacoes: [] };

const notificacoes = (state = initNotificacoes, action) => {
	switch (action.type) {
		case UPDATE_NOTIFICACOES:
			return {
				notificacoes: action.notificacoes,
			};
		default:
			return state;
	}
};

export default notificacoes;
