import { UPDATE_USER, UPDATE_UNIDADE, ID_UNIDADE } from "../constants/User";

const initUser = {
	name: null,
	role: null,
	unidade: { idunidade: null, nome: null },
	idusuario: null,
	franqueador_acess: null,
};

const user = (state = initUser, action) => {
	switch (action.type) {
		case UPDATE_USER:
			localStorage.setItem(ID_UNIDADE, action.unidade.idunidade);
			return {
				...state,
				name: action.name,
				role: action.role,
				unidade: action.unidade,
				idusuario: action.idusuario,
				franqueador_acess: action.franqueador_acess,
			};
		case UPDATE_UNIDADE:
			localStorage.setItem(ID_UNIDADE, action.unidade.idunidade);
			return {
				...state,
				unidade: action.unidade,
			};
		default:
			return state;
	}
};

export default user;
