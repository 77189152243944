import fetch from "auth/FetchInterceptor";
import { UPDATE_USER, UPDATE_UNIDADE } from "redux/constants/User";
import { UPDATE_PERMISSOES } from "redux/constants/Permissoes";
import store from "redux/store";
import { UPDATE_NOTIFICACOES } from "redux/constants/Notificacoes";
import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";

const menuService = {};

/*
    Injeta na store do usuário os dados do menu
*/

menuService.populate = (menuData) => {
	store.dispatch({
		type: UPDATE_USER,
		name: menuData.user.nome,
		role: "Usuário",
		unidade: menuData.user.unidade,
		franqueador_acess: menuData.user.franqueador_acess,
		idusuario: menuData.user.idusuario,
	});

	store.dispatch({
		type: UPDATE_PERMISSOES,
		permissoes: menuData.permissoes,
	});

	store.dispatch({
		type: UPDATE_NOTIFICATIONS,
		mensagensNaoLidas: menuData.total_mensagens_nao_lidas,
	});

	store.dispatch({
		type: UPDATE_NOTIFICACOES,
		notificacoes: menuData.notificacoes,
	});
};

menuService.populateMenuData = async function () {
	const userState = store.getState().user;
	const authState = store.getState().auth;
	if (!userState.name && authState.token) {
		const menuData = await menuService.getMenuData();
		menuService.populate(menuData);
	}
};

menuService.populateUnidade = async function () {
	const menuData = await menuService.getMenuData();

	store.dispatch({
		type: UPDATE_UNIDADE,
		unidade: menuData.user.unidade,
	});
};

menuService.getMenuData = function () {
	return fetch({
		url: "/v1/rest/menu",
		method: "get",
	});
};

menuService.increaseNotifications = function (notification) {
	store.dispatch({
		type: UPDATE_NOTIFICATIONS,
		[notification]: store.getState().menu[notification] + 1,
	});
};

menuService.decreaseNotifications = function (notification) {
	store.dispatch({
		type: UPDATE_NOTIFICATIONS,
		[notification]: store.getState().menu[notification] - 1,
	});
};

menuService.resetNotifications = function (notification) {
	store.dispatch({
		type: UPDATE_NOTIFICATIONS,
		[notification]: 0,
	});
};

export default menuService;
