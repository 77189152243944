import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { CAIXA_PREFIX_PATH } from "configs/AppConfig";

export const TreinamentoViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${CAIXA_PREFIX_PATH}/faturas`}
					component={lazy(() => import(`./faturas`))}
				/>
				<Redirect
					from={`${CAIXA_PREFIX_PATH}`}
					to={`${CAIXA_PREFIX_PATH}/faturas`}
				/>
			</Switch>
		</Suspense>
	);
};

export default TreinamentoViews;
