import { useEffect } from "react";
import { useParams } from "react-router-dom";
import redirectService from "services/RedirectService";
import { PUBLIC_PREFIX_PATH } from "configs/AppConfig";

const RedirectViews = () => {
	const { hash } = useParams();

	const getLink = async () => {
		const { link } = await redirectService.get(hash);

		if (link) {
			window.open(link, "_self");
		}
	};

	const getLoading = () => {
		return `${PUBLIC_PREFIX_PATH}/oralbrasil_loading.gif`;
	};

	useEffect(() => {
		getLink();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// aguarde iremos redirecionar você para a página
	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				backgroundImage: `url(${PUBLIC_PREFIX_PATH}/bannerOralBrasil.jpg)`,
			}}
		>
			<div
				style={{
					position: "relative",
					width: 550,
					height: 400,
					borderRadius: 24,
					border: "3px solid #fff",
					boxSizing: "border-box",
					overflow: "hidden",
					backdropFilter: "blur(5px)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div
					style={{
						position: "absolute",
						width: "100%",
						height: "100%",
						opacity: 0.7,
						backgroundImage:
							"linear-gradient(180deg, #0F237C, #2489B4)",
					}}
				/>
				<div
					style={{
						width: 357,
						height: 300,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						zIndex: 1,
					}}
				>
					<img
						src={getLoading()}
						alt="Oral Brasil"
						style={{
							height: 120,
							width: 120,
							marginBottom: 16,
						}}
					/>
					<div
						style={{
							fontWeight: 700,
							fontSize: 40,
							color: "#fff",
							marginBottom: 16,
						}}
					>
						Aguarde
					</div>
					<div
						style={{
							fontWeight: 400,
							fontSize: 24,
							color: "#fff",
							textAlign: "center",
						}}
					>
						<div>Estamos redirecionando</div>
						<div>você para a página desejada</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RedirectViews;
