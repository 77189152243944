import {
	BarChartOutlined,
	UserOutlined,
	DollarOutlined,
	CalendarOutlined,
	DatabaseOutlined,
	PlayCircleOutlined,
	SettingOutlined,
	MedicineBoxOutlined,
	ContainerOutlined,
	ShoppingCartOutlined,
	SolutionOutlined,
	EnvironmentOutlined,
	FieldTimeOutlined,
	ExceptionOutlined,
	LikeOutlined,
	ShopOutlined,
	WhatsAppOutlined,
	GlobalOutlined,
	FormOutlined,
	SafetyCertificateOutlined,
	BarcodeOutlined,
	AuditOutlined,
	LineChartOutlined,
	TeamOutlined,
	UngroupOutlined,
	GroupOutlined,
	NotificationOutlined,
	TagOutlined,
	ProjectOutlined,
	CommentOutlined,
	SoundOutlined,
	// MobileOutlined,
} from "@ant-design/icons";
import {
	APP_PREFIX_PATH,
	CAIXA_PREFIX_PATH,
	FRANQUEADORA_PREFIX_PATH,
} from "configs/AppConfig";
import { AsaasSVG } from "assets/svg/icon";
import Permissoes from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "inteligencia",
		path: `${APP_PREFIX_PATH}/inteligencia`,
		title: "inteligencia",
		icon: BarChartOutlined,
		breadcrumb: false,
		permissao: Permissoes.MENU_LATERAL.INTELIGENCIA,
		submenu: [],
	},
	{
		key: "vendas",
		path: `${APP_PREFIX_PATH}/vendas`,
		title: "vendas",
		icon: ProjectOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.LEADS,
	},
	{
		key: "agenda",
		path: `${APP_PREFIX_PATH}/agenda`,
		title: "agenda",
		icon: CalendarOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.AGENDA,
	},
	{
		key: "lista-pacientes",
		path: `${APP_PREFIX_PATH}/lista-pacientes`,
		title: "lista-pacientes",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.PACIENTES,
	},

	{
		key: "relacao_orcamentos",
		path: `${APP_PREFIX_PATH}/relacao-de-orcamentos`,
		title: "relacao_orcamentos",
		icon: AuditOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.RELATORIOS,
	},
	{
		key: "financeiro",
		path: `${APP_PREFIX_PATH}/financeiro`,
		title: "financeiro",
		icon: DollarOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.FINANCEIRO,
	},
	{
		key: "estoque",
		path: `${APP_PREFIX_PATH}/lista-estoque`,
		title: "lista-estoque",
		icon: DatabaseOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.ESTOQUE,
	},
	{
		key: "chat",
		path: `${APP_PREFIX_PATH}/chat`,
		title: "chat",
		icon: CommentOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.CHAT,
		indexNotificacoes: "mensagensNaoLidas",
	},
	// {
	// 	key: 'whatsapp',
	// 	path: `${APP_PREFIX_PATH}/whatsapp`,
	// 	title: 'whatsapp',
	// 	icon: WhatsAppOutlined,
	// 	breadcrumb: false,
	// 	submenu: [],
	// 	permissao: Permissoes.MENU_LATERAL.WHATSAPP,
	// },
	{
		key: "avaliacao",
		path: `${APP_PREFIX_PATH}/avaliacao`,
		title: "avaliacao",
		icon: LikeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.AVALIACOES_UNIDADE,
	},
	{
		key: "ouvidoria",
		path: `${APP_PREFIX_PATH}/ouvidoria`,
		title: "ouvidoria",
		icon: SoundOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.OUVIDORIA,
	},
	{
		key: "campanhas",
		path: `${APP_PREFIX_PATH}/campanhas`,
		title: "campanhas",
		icon: NotificationOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.MENU_LATERAL.CAMPANHAS,
	},
	// {
	// 	key: 'assinar-aplicativo',
	// 	path: `${APP_PREFIX_PATH}/assinar-aplicativo`,
	// 	title: 'assinar-aplicativo',
	// 	icon: MobileOutlined,
	// 	breadcrumb: false,
	// 	submenu: [],
	// 	permissao: Permissoes.MENU_LATERAL.ASSINAR_APLICATIVO,
	// },
	{
		title: "secundario",
		key: "secundario",
		showTitle: true,
		submenu: [
			{
				key: "cadastros-secundarios",
				title: "cadastros-secundarios",
				icon: FormOutlined,
				submenu: [
					{
						key: "cadastros-secundarios/unidade",
						title: "cadastros-secundarios/unidade",
						icon: EnvironmentOutlined,
						submenu: [
							{
								key: "cadastros-secundarios/lista-bonificacoes",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-bonificacoes`,
								title: "cadastros-secundarios/lista-bonificacoes",
								icon: DollarOutlined,
								permissao: Permissoes.MENU_LATERAL.BONIFICACOES,
								breadcrumb: false,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-clientes-planos",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-clientes-planos`,
								title: "cadastros-secundarios/lista-clientes-planos",
								icon: SolutionOutlined,
								breadcrumb: false,
								permissao:
									Permissoes.MENU_LATERAL.PACIENTES_POR_PLANO,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-especialidades",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-especialidades`,
								title: "cadastros-secundarios/lista-especialidades",
								icon: MedicineBoxOutlined,
								breadcrumb: false,
								permissao:
									Permissoes.MENU_LATERAL.ESPECIALIDADES,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-fornecedores",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-fornecedores`,
								title: "cadastros-secundarios/lista-fornecedores",
								icon: ShoppingCartOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.FORNECEDORES,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-metas",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-metas`,
								title: "cadastros-secundarios/lista-metas",
								icon: LineChartOutlined,
								permissao: Permissoes.MENU_LATERAL.METAS,
								breadcrumb: false,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-planos",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-planos`,
								title: "cadastros-secundarios/lista-planos",
								icon: ContainerOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.PLANOS,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-rotulos",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-rotulos`,
								title: "cadastros-secundarios/lista-rotulos",
								icon: TagOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.ROTULO,
								submenu: [],
							},
						],
					},
					{
						key: "cadastros-secundarios/geral",
						title: "cadastros-secundarios/geral",
						icon: GlobalOutlined,
						submenu: [
							{
								key: "cadastros-secundarios/lista-produtos-ecommerce",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-produtos-ecommerce`,
								title: "cadastros-secundarios/lista-produtos-ecommerce",
								icon: ShoppingCartOutlined,
								breadcrumb: false,
								permissao:
									Permissoes.MENU_LATERAL.PRODUTO_ECOMMERCE,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-agencia",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-agencia`,
								title: "cadastros-secundarios/lista-agencia",
								icon: ShopOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.AGENCIA,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-anamnese",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-anamneses`,
								title: "cadastros-secundarios/lista-anamneses",
								icon: ExceptionOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.ANAMNESE,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-contas-despesas",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-contas-despesas`,
								title: "cadastros-secundarios/lista-contas-despesas",
								icon: AuditOutlined,
								breadcrumb: false,
								permissao:
									Permissoes.MENU_LATERAL.CONTA_DESPESA,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-empresas",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-empresas`,
								title: "cadastros-secundarios/lista-empresas",
								icon: ShopOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.EMPRESA,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-indicacoes",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-indicacoes`,
								title: "cadastros-secundarios/lista-indicacoes",
								icon: LikeOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.INDICACOES,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-registros",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-registros`,
								title: "cadastros-secundarios/lista-registros",
								icon: FieldTimeOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.REGISTROS,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-unidades",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-unidades`,
								title: "cadastros-secundarios/lista-unidades",
								icon: EnvironmentOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.UNIDADE,
								submenu: [],
							},
							{
								key: "cadastros-secundarios/lista-usuarios",
								path: `${APP_PREFIX_PATH}/cadastros-secundarios/lista-usuarios`,
								title: "cadastros-secundarios/lista-usuarios",
								icon: UserOutlined,
								breadcrumb: false,
								permissao: Permissoes.MENU_LATERAL.USUARIOS,
								submenu: [],
							},
						],
					},
				],
				permissao: Permissoes.MENU_LATERAL.CADASTROS_SECUNDARIOS,
			},
			{
				key: "configuracoes",
				title: "configuracoes",
				icon: SettingOutlined,
				submenu: [
					{
						key: "configuracoes/lista-permissoes",
						path: `${APP_PREFIX_PATH}/configuracoes/lista-permissoes`,
						title: "configuracoes/lista-permissoes",
						icon: SafetyCertificateOutlined,
						breadcrumb: false,
						permissao: Permissoes.MENU_LATERAL.PERMISSOES,
						submenu: [],
					},
					{
						key: "configuracoes/lista-formas-pagamento",
						path: `${APP_PREFIX_PATH}/configuracoes/lista-formas-pagamento`,
						title: "configuracoes/lista-formas-pagamento",
						icon: BarcodeOutlined,
						breadcrumb: false,
						permissao: Permissoes.MENU_LATERAL.FORMA_PAGAMENTO,
						submenu: [],
					},
					{
						key: "configuracoes/whatsapp",
						path: `${APP_PREFIX_PATH}/configuracoes/whatsapp`,
						title: "configuracoes/whatsapp",
						icon: WhatsAppOutlined,
						breadcrumb: false,
						submenu: [],
						permissao:
							Permissoes.MENU_LATERAL.GERENCIAMENTO_WHATSAPP,
					},
				],
				permissao: Permissoes.MENU_LATERAL.CONFIGURACOES,
			},
		],
	},
	{
		title: "apoio",
		key: "apoio",
		showTitle: true,
		permissao: Permissoes.MENU_LATERAL.AULAS,
		submenu: [
			{
				key: "treinamentos",
				path: `${APP_PREFIX_PATH}/treinamentos`,
				title: "treinamentos",
				permissao: Permissoes.MENU_LATERAL.TREINAMENTOS,
				icon: PlayCircleOutlined,
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		title: "sistema",
		key: "sistema",
		showTitle: true,
		permissao: Permissoes.FATURAS.VISUALIZAR,
		submenu: [
			{
				key: "sistema/faturas",
				path: `${CAIXA_PREFIX_PATH}/faturas`,
				title: "sistema/faturas",
				permissao: Permissoes.FATURAS.VISUALIZAR,
				icon: DollarOutlined,
				breadcrumb: false,
				submenu: [],
			},
		],
	},
];

const navigationConfig = [...dashBoardNavTree];

export const navigationConfigFranqueadora = [
	{
		key: "inteligencia",
		path: `${FRANQUEADORA_PREFIX_PATH}/inteligencia`,
		title: "inteligencia",
		icon: BarChartOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "financeiro",
		path: `${FRANQUEADORA_PREFIX_PATH}/financeiro`,
		title: "financeiro",
		icon: DollarOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "lista-clientes",
		path: `${FRANQUEADORA_PREFIX_PATH}/lista-clientes`,
		title: "lista-clientes",
		icon: TeamOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		title: "secundario",
		key: "secundario",
		showTitle: true,
		submenu: [
			{
				key: "franqueadora/cadastros",
				title: "franqueadora/cadastros",
				icon: FormOutlined,
				submenu: [
					{
						key: "franqueadora/cadastros/lista-categorias-financeiro",
						path: `${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-categoria-financeiro`,
						title: "franqueadora/cadastros/lista-categorias-financeiro",
						icon: GroupOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "franqueadora/cadastros/lista-subcategorias-financeiro",
						path: `${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-subcategoria-financeiro`,
						title: "franqueadora/cadastros/lista-subcategorias-financeiro",
						icon: UngroupOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "franqueadora/cadastros/lista-contas-financeiro",
						path: `${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-conta-financeiro`,
						title: "franqueadora/cadastros/lista-contas-financeiro",
						icon: AuditOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "franqueadora/cadastros/lista-fornecedor",
						path: `${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-fornecedor`,
						title: "franqueadora/cadastros/lista-fornecedor",
						icon: ShoppingCartOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "franqueadora/cadastros/lista-meta-unidade",
						path: `${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-meta-unidade`,
						title: "franqueadora/cadastros/lista-meta-unidade",
						icon: LineChartOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "cadastros-secundarios/lista-asaas",
						path: `${FRANQUEADORA_PREFIX_PATH}/cadastros-secundarios/lista-asaas`,
						title: "cadastros-secundarios/lista-asaas",
						icon: AsaasSVG,
						breadcrumb: false,
						submenu: [],
					},
				],
			},
		],
	},
];

export default navigationConfig;
