import React from "react";
import LoginForm from "./LoginForm";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { PUBLIC_PREFIX_PATH } from "configs/AppConfig";

const LoginOne = (props) => {
	const theme = useSelector((state) => state.theme.currentTheme);
	return (
		<>
			<div className="text-center">
				<img
					className="img-fluid"
					src={`${
						theme === "light"
							? `${PUBLIC_PREFIX_PATH}/oralbrasilazul.png`
							: `${PUBLIC_PREFIX_PATH}/oralbrasilazul.png`
					}`}
					alt=""
				/>
			</div>
			<Row justify="center" style={{ marginTop: 15 }}>
				<Col xs={24} sm={24} md={20} lg={20}>
					<LoginForm {...props} />
				</Col>
			</Row>
		</>
	);
};

export default LoginOne;
