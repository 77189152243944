import { Button, Col, Form, Input, Row } from "antd";
import Loading from "components/atom/Loading";
import { FRANQUEADORA_PREFIX_PATH } from "configs/AppConfig";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { authenticated_franqueadora } from "redux/actions/Auth";
import JwtAuthService from "services/JwtAuthService";

const FranqueadoraSenhaModal = (props) => {
	const { closeModal, authenticated_franqueadora } = props;

	const [isLoading, setIsLoading] = useState(false);

	const [form] = Form.useForm();

	let history = useHistory();

	const onFinish = async (values) => {
		setIsLoading(true);
		const auth = await JwtAuthService.loginFranqueadora(values.senha);
		if (auth.token_franqueadora) {
			const token_franqueadora = `${auth.token_type} ${auth.token_franqueadora}`;
			authenticated_franqueadora(token_franqueadora);
			history.push(FRANQUEADORA_PREFIX_PATH);
			closeModal();
		}
		setIsLoading(false);
	};

	return (
		<Loading isLoading={isLoading}>
			<Form
				form={form}
				onFinish={onFinish}
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				layout={"vertical"}
			>
				<Row>
					<Col xs={24} md={24}>
						<Form.Item
							label="Senha"
							name="senha"
							rules={[
								{
									required: true,
									message:
										"Por favor, digite a senha da franqueadora.",
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col xs={24} md={24}>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								style={{ float: "right" }}
							>
								Entrar
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Loading>
	);
};

const mapStateToProps = ({ auth }) => {
	const { token, redirect } = auth;
	return { token, redirect };
};

const mapDispatchToProps = {
	authenticated_franqueadora,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FranqueadoraSenhaModal);
