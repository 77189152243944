import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";

// controlador de modal, controla se ele vai estar visivel ou não, é passado o botão para dentro tambem

const ModalController = (props) => {
	const {
		renderForm,
		width,
		minWidth,
		renderButton,
		title,
		style,
		bodyStyle,
		show,
		onClose,
		forceRender,
	} = props;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [firstCall, setFirstCall] = useState(true);

	const onClick = () => {
		setIsModalVisible(true);
	};

	const closeModal = async () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		if (!firstCall) {
			if (!isModalVisible && onClose) {
				onClose();
			}
		} else {
			setFirstCall(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModalVisible]);

	useEffect(() => {
		if (show) {
			setIsModalVisible(true);
		}
	}, [show]);

	return (
		<div>
			{renderButton({ onClick })}
			<Modal
				forceRender={forceRender}
				visible={isModalVisible}
				destroyOnClose={true}
				footer={null}
				onCancel={closeModal}
				maskClosable={false}
				width={width}
				minWidth={minWidth}
				bodyStyle={bodyStyle}
				title={title}
				style={style}
			>
				{renderForm({ closeModal, isModalVisible })}
			</Modal>
		</div>
	);
};

export default ModalController;
