import fetch from "auth/FetchInterceptor";

const metaService = {};

metaService.get = async function (params = {}) {
	const {
		page = 0,
		pageSize = 5,
		search = "",
		orderBy = "idmeta",
		orderDirection = "asc",
		filters = {},
	} = params;

	const queryParams = {
		page,
		pageSize,
		search,
		orderBy,
		orderDirection,
		filters,
	};

	return fetch({
		url: "/v1/rest/meta",
		method: "get",
		params: queryParams,
	});
};

metaService.getAll = async function (values) {
	return fetch({
		url: "/v1/rest/meta",
		method: "get",
		params: values,
	});
};

metaService.post = async function (values) {
	return fetch({
		url: "/v1/rest/meta",
		method: "post",
		data: values,
	});
};

metaService.put = async function (values, id) {
	return fetch({
		url: "/v1/rest/meta/" + id,
		method: "put",
		data: values,
	});
};
metaService.getById = async (id) => {
	return fetch({
		url: `/v1/rest/meta/${id}`,
		method: "get",
	});
};

metaService.delete = async (id) => {
	return fetch({
		url: `/v1/rest/meta/${id}`,
		method: "delete",
	});
};

metaService.getAllByUser = async function () {
	return fetch({
		url: "/v1/rest/meta-usuario",
		method: "get",
	});
};
metaService.pagarMeta = async function (id) {
	return fetch({
		url: "/v1/rest/pagar-meta/" + id,
		method: "post",
	});
};

export default metaService;
