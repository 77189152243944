import { SET_IS_LOADING, SET_TOKEN_EXPIRED } from "redux/constants/Gui";

const initGui = {
	isLoading: false,
	isExpired: false,
};

const gui = (state = initGui, action) => {
	switch (action.type) {
		case SET_IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case SET_TOKEN_EXPIRED:
			return {
				...state,
				isExpired: action.isExpired,
			};
		default:
			return state;
	}
};

export default gui;
