import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Alert } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import {
	showLoading,
	showAuthMessage,
	hideAuthMessage,
	authenticated,
} from "redux/actions/Auth";
import JwtAuthService from "services/JwtAuthService";
import { setTokenExpired } from "redux/actions/Gui";
import { motion } from "framer-motion";
import { AUTH_TOKEN } from "redux/constants/Auth";

export const LoginForm = (props) => {
	const {
		showForgetPassword,
		hideAuthMessage,
		onForgetPasswordClick,
		showLoading,
		extra,
		loading,
		setTokenExpired,
		showMessage,
		message,
		authenticated,
		showAuthMessage,
	} = props;

	const onLogin = (values) => {
		showLoading();
		JwtAuthService.login(values.email.toLowerCase(), values.password)
			.then(async (resp) => {
				try {
					const token = `${resp.token_type} ${resp.token}`;
					authenticated(token);
				} catch (e) {}
			})
			.then((e) => {
				showAuthMessage(e);
			})
			.catch((e) => {
				showLoading(false);
			});
	};

	function inputLowerCase() {
		const input = document.getElementById("login-form_email");
		input.style.textTransform = "lowercase";
	}

	useEffect(() => {
		let tokenExpired = localStorage.getItem(AUTH_TOKEN);
		if (tokenExpired !== null) {
			setTokenExpired(false);
		}
		if (showMessage) {
			setTimeout(() => {
				hideAuthMessage();
			}, 3000);
		}
		inputLowerCase();
	});

	return (
		<>
			<motion.div
				initial={{ opacity: 0, marginBottom: 0 }}
				animate={{
					opacity: showMessage ? 1 : 0,
					marginBottom: showMessage ? 20 : 0,
				}}
			>
				<Alert type="error" showIcon message={message}></Alert>
			</motion.div>
			<Form layout="vertical" name="login-form" onFinish={onLogin}>
				<Form.Item
					name="email"
					label="E-mail"
					rules={[
						{
							required: true,
							message: "Por favor insira seu e-mail!",
						},
						{
							type: "email",
							message: "Por favor, insira um e-mail válido!",
						},
					]}
				>
					<Input prefix={<MailOutlined className="text-primary" />} />
				</Form.Item>
				<Form.Item
					name="password"
					label={
						<div
							className={`${
								showForgetPassword
									? "d-flex justify-content-between w-100 align-items-center"
									: ""
							}`}
						>
							<span>Senha</span>
							{showForgetPassword && (
								<span
									onClick={() => onForgetPasswordClick}
									className="cursor-pointer font-size-sm font-weight-normal text-muted"
								>
									Esqueceu a senha?
								</span>
							)}
						</div>
					}
					rules={[
						{
							required: true,
							message: "Por favor, insira sua senha!",
						},
					]}
				>
					<Input.Password
						prefix={<LockOutlined className="text-primary" />}
					/>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						block
						loading={loading}
					>
						Entrar
					</Button>
				</Form.Item>
				{extra}
			</Form>
		</>
	);
};

LoginForm.propTypes = {
	otherSignIn: PropTypes.bool,
	showForgetPassword: PropTypes.bool,
	extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

LoginForm.defaultProps = {
	otherSignIn: true,
	showForgetPassword: false,
};

const mapStateToProps = ({ auth }) => {
	const { loading, message, showMessage, token, redirect } = auth;
	return { loading, message, showMessage, token, redirect };
};

const mapDispatchToProps = {
	showAuthMessage,
	showLoading,
	hideAuthMessage,
	setTokenExpired,
	authenticated,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
