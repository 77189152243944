import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "redux/constants/Auth";

const JwtAuthService = {};

// TODO: após clonar o skeleton, ajustar essa função
JwtAuthService.login = function (usuario, senha_node) {
	const body = {
		usuario: usuario,
		senha_node: senha_node,
	};

	return fetch({
		url: "/v1/rest/login",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: body,
	});
};

JwtAuthService.loginFranqueadora = function (senha) {
	const body = {
		senha: senha,
	};

	return fetch({
		url: "/v2/rest/login-franqueadora",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: body,
	});
};
JwtAuthService.login = function (usuario, senha_node, tokenCaptcha) {
	const body = {
		usuario: usuario,
		senha_node: senha_node,
		tokenCaptcha,
	};

	return fetch({
		url: "/v1/rest/login",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: body,
	});
};

JwtAuthService.signUp = function (data) {
	return fetch({
		url: "/auth/signup",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: data,
	});
};

JwtAuthService.isLogged = () => {
	return localStorage.getItem(AUTH_TOKEN) !== null;
};

export default JwtAuthService;
