import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
	APP_PREFIX_PATH,
	AUTH_PREFIX_PATH,
	CAIXA_PREFIX_PATH,
	FRANQUEADORA_PREFIX_PATH,
	REDIRECT_PATH,
	TREINAMENTO_PREFIX_PATH,
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import TreinamentoLayout from "../layouts/treinamento-layout";
import FranqueadoraLayout from "layouts/franqueadora-layout";
import CaixaLayout from "layouts/caixa-layout";
import RedirectViews from "./redirect-views";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: AUTH_PREFIX_PATH,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export const Views = (props) => {
	const { locale, token, token_franqueadora, location, direction } = props;
	const currentAppLocale = AppLocale[locale];
	useBodyClass(`dir-${direction}`);
	return (
		<IntlProvider
			locale={currentAppLocale.locale}
			messages={currentAppLocale.messages}
		>
			<ConfigProvider
				locale={currentAppLocale.antd}
				direction={direction}
			>
				<Switch>
					<Route exact path="/">
						<Redirect to={APP_PREFIX_PATH} />
					</Route>
					<Route exact path="/">
						<Redirect to={FRANQUEADORA_PREFIX_PATH} />
					</Route>
					<Route exact path="/">
						<Redirect to={TREINAMENTO_PREFIX_PATH} />
					</Route>
					<Route exact path="/">
						<Redirect to={CAIXA_PREFIX_PATH} />
					</Route>
					<Route path={`${REDIRECT_PATH}/:hash`}>
						<RedirectViews />
					</Route>
					<Route path={AUTH_PREFIX_PATH}>
						<AuthLayout direction={direction} />
					</Route>
					<RouteInterceptor
						path={APP_PREFIX_PATH}
						isAuthenticated={token}
					>
						<AppLayout direction={direction} location={location} />
					</RouteInterceptor>
					<RouteInterceptor
						path={FRANQUEADORA_PREFIX_PATH}
						isAuthenticated={token_franqueadora}
					>
						<FranqueadoraLayout
							direction={direction}
							location={location}
						/>
					</RouteInterceptor>
					<RouteInterceptor
						path={CAIXA_PREFIX_PATH}
						isAuthenticated={token}
					>
						<CaixaLayout
							direction={direction}
							location={location}
						/>
					</RouteInterceptor>
					<RouteInterceptor
						path={TREINAMENTO_PREFIX_PATH}
						isAuthenticated={token}
					>
						<TreinamentoLayout
							direction={direction}
							location={location}
						/>
					</RouteInterceptor>
				</Switch>
			</ConfigProvider>
		</IntlProvider>
	);
};

const mapStateToProps = ({ theme, auth }) => {
	const { locale, direction } = theme;
	const { token, token_franqueadora } = auth;
	return { locale, token, token_franqueadora, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
