import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { FRANQUEADORA_PREFIX_PATH } from "configs/AppConfig";

export const FranqueadoraViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/inteligencia`}
					component={lazy(() => import(`./inteligencia`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/financeiro`}
					component={lazy(() => import(`./financeiro`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/lista-clientes`}
					component={lazy(() => import(`./lista-clientes`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cliente/:id`}
					component={lazy(() => import(`./cliente`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cliente`}
					component={lazy(() => import(`./cliente`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cliente-visualizar/:id`}
					component={lazy(() => import(`./cliente-visualizar`))}
				/>

				{/*Relatórios*/}

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/relatorios/dre`}
					component={lazy(() => import(`./relatorios/dre`))}
				/>

				{/*Cadastros secundarios*/}

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/fornecedor/:id`}
					component={lazy(() => import(`./cadastros/fornecedor`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/fornecedor`}
					component={lazy(() => import(`./cadastros/fornecedor`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-fornecedor`}
					component={lazy(() =>
						import(`./cadastros/lista-fornecedor`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/categoria-financeiro/:id`}
					component={lazy(() =>
						import(`./cadastros/categoria-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/categoria-financeiro`}
					component={lazy(() =>
						import(`./cadastros/categoria-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-categoria-financeiro`}
					component={lazy(() =>
						import(`./cadastros/lista-categoria-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/subcategoria-financeiro/:id`}
					component={lazy(() =>
						import(`./cadastros/subcategoria-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/subcategoria-financeiro`}
					component={lazy(() =>
						import(`./cadastros/subcategoria-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-subcategoria-financeiro`}
					component={lazy(() =>
						import(`./cadastros/lista-subcategoria-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/meta-unidade/:id`}
					component={lazy(() => import(`./cadastros/meta-unidade`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/meta-unidade`}
					component={lazy(() => import(`./cadastros/meta-unidade`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-meta-unidade`}
					component={lazy(() =>
						import(`./cadastros/lista-meta-unidade`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/conta-financeiro/:id`}
					component={lazy(() =>
						import(`./cadastros/conta-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/conta-financeiro`}
					component={lazy(() =>
						import(`./cadastros/conta-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros/lista-conta-financeiro`}
					component={lazy(() =>
						import(`./cadastros/lista-conta-financeiro`),
					)}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros-secundarios/token-asaas/:id`}
					component={lazy(() => import(`./cadastros/token-asaas`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros-secundarios/token-asaas`}
					component={lazy(() => import(`./cadastros/token-asaas`))}
				/>

				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/cadastros-secundarios/lista-asaas`}
					component={lazy(() => import(`./cadastros/lista-asaas`))}
				/>

				{/* Páginas de erro */}
				<Route
					path={`${FRANQUEADORA_PREFIX_PATH}/error/404`}
					component={lazy(() => import(`./error/404`))}
				/>

				<Redirect
					from={`${FRANQUEADORA_PREFIX_PATH}`}
					to={`${FRANQUEADORA_PREFIX_PATH}/inteligencia`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(FranqueadoraViews);
