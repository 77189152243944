import { CLEAR_DATA, SET_DATA } from "redux/constants/Data";

const data = (state = {}, action) => {
	switch (action.type) {
		case SET_DATA:
			const data = { ...action };
			delete data.type;
			return {
				...state,
				...data,
			};
		case CLEAR_DATA:
			return {};
		default:
			return state;
	}
};

export default data;
