import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";

const initMenu = {
	mensagensNaoLidas: 0,
};

const menu = (state = initMenu, action) => {
	switch (action.type) {
		case UPDATE_NOTIFICATIONS:
			return {
				...state,
				mensagensNaoLidas: action.mensagensNaoLidas,
			};
		default:
			return state;
	}
};

export default menu;
