import { Button } from "antd";
import { APP_PREFIX_PATH, PUBLIC_PREFIX_PATH } from "configs/AppConfig";
import React from "react";
import { ImportOutlined } from "@ant-design/icons";
import { Switch, Route, useHistory } from "react-router-dom";
import CaixaViews from "views/caixa-views";

export const CaixaLayout = () => {
	let history = useHistory();

	const getLogo = () => {
		return `${PUBLIC_PREFIX_PATH}/oralbrasil.png`;
	};

	return (
		<div className="device-container">
			<header
				style={{
					backgroundColor: "#073777",
					height: "50px",
					width: "100%",
				}}
			>
				<Button
					type="primary"
					onClick={() => {
						history.push(APP_PREFIX_PATH + "/inteligencia");
					}}
					style={{ float: "left", margin: "5px" }}
					icon={<ImportOutlined />}
				/>
				<div
					style={{
						margin: "auto",
						width: "min-content",
						paddingRight: "20px",
					}}
				>
					<img
						src={getLogo()}
						alt={`logo`}
						style={{
							marginTop: "5px",
							height: "40px",
						}}
					/>
				</div>
			</header>
			<Switch>
				<Route path="" component={CaixaViews} />
			</Switch>
			<footer
				style={{
					position: "fixed",
					backgroundColor: "#073777",
					height: "50px",
					width: "100%",
					bottom: 0,
				}}
			></footer>
		</div>
	);
};

export default CaixaLayout;
