import fetch from "auth/FetchInterceptor";
import store from "../redux/store";
import { HomeOutlined } from "@ant-design/icons";
import { UPDATE_TREINAMENTO } from "../redux/constants/MenuTreinamento";
import { StatusGeraisSVG } from "../assets/svg/icon";
import { TREINAMENTO_PREFIX_PATH } from "../configs/AppConfig";

const treinamentoService = {};

const elementoMenuStatico = {
	key: "dashboard",
	path: `${TREINAMENTO_PREFIX_PATH}/dashboard`,
	title: "Menu",
	icon: HomeOutlined,
	breadcrumb: false,
	submenu: [],
};
treinamentoService.populateMenuData = async function () {
	let menuTreinamentoData = await treinamentoService.getMenuTreinamentoData();

	const menuTreinamento = await menuTreinamentoData.map((categoria) => {
		return {
			showTitle: true,
			title: categoria.nome,
			submenu: categoria.lista_modulos_curso.map((modulo) => {
				return {
					key: `${modulo.id}`,
					title: modulo.nome,
					path: `${TREINAMENTO_PREFIX_PATH}/modulos/${modulo.id}`,
					icon: StatusGeraisSVG,
					breadcrumb: false,
					submenu: [],
				};
			}),
		};
	});

	menuTreinamento.unshift(elementoMenuStatico);
	store.dispatch({
		type: UPDATE_TREINAMENTO,
		navigationConfig: menuTreinamento,
	});
};

treinamentoService.getMenuTreinamentoData = async function () {
	return fetch({
		url: "/v1/rest/menu-treinamento",
		method: "get",
	});
};

treinamentoService.getById = async (id) => {
	return fetch({
		url: `/v1/rest/modulo-curso/${id}`,
		method: "get",
	});
};

export default treinamentoService;
