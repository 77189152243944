import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Layout, Menu, Tooltip } from "antd";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	StockOutlined,
} from "@ant-design/icons";
import Logo from "../Logo";
import NavSearch from "../NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
	NAV_TYPE_TOP,
	SIDE_NAV_COLLAPSED_WIDTH,
	SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import NavProfile from "../NavProfile";
import MetasUsuario from "../MetasUsuario";
import { DownOutlined } from "@ant-design/icons";
import dropDownService from "services/DropDownUnidades";
import menuService from "../../../services/MenuService";
import Swal from "sweetalert2";
import { setIsLoading } from "../../../redux/actions/Gui";
import { APP_PREFIX_PATH, CAIXA_PREFIX_PATH } from "configs/AppConfig";
import { Link, useHistory } from "react-router-dom";
import ModalController from "components/organism/ModalController";
import NavNotification from "../NavNotification";
import FranqueadoraSenhaModal from "../franqueadora-senha-modal";
import "./styles.css";
import moment from "moment";
import Permissoes from "enum/permissao-enum";
import TemPermissao from "components/atom/TemPermissao";

const { Header } = Layout;

export const HeaderNav = (props) => {
	const {
		user,
		navCollapsed,
		mobileNav,
		navType,
		headerNavColor,
		toggleCollapsedNav,
		onMobileNavToggle,
		isMobile,
		currentTheme,
		franqueadora,
	} = props;
	const [searchActive, setSearchActive] = useState(false);
	const [opcoes, setOpcoes] = useState([]);
	const [tempoFaltando, setTempoFaltando] = useState();
	const [dataVencimento, setDataVencimento] = useState();
	const [dataBloqueio, setDataBloqueio] = useState();

	const [dadosMenu, setDadosMenu] = useState([]);
	let history = useHistory();

	const onSearchClose = () => {
		setSearchActive(false);
	};

	const onToggle = () => {
		if (!isMobile) {
			toggleCollapsedNav(!navCollapsed);
		} else {
			onMobileNavToggle(!mobileNav);
		}
	};

	const isNavTop = navType === NAV_TYPE_TOP;
	const mode = () => {
		if (!headerNavColor) {
			return utils.getColorContrast(
				currentTheme === "dark" ? "#00000" : "#ffffff",
			);
		}
		return utils.getColorContrast(headerNavColor);
	};
	const navMode = mode();
	const getNavWidth = () => {
		if (isNavTop || isMobile) {
			return "0px";
		}
		if (navCollapsed) {
			return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
		} else {
			return `${SIDE_NAV_WIDTH}px`;
		}
	};

	const getOpcoes = async () => {
		setOpcoes(await dropDownService.getAll());
		setDadosMenu(await menuService.getMenuData());
	};

	const onClick = async ({ key }) => {
		await dropDownService.put(key);
		await menuService.populateUnidade();
		await window.location.reload();
	};

	const obterDados = async () => {
		try {
			setIsLoading(true);
			await Promise.all([getOpcoes()]);
			setIsLoading(false);
		} catch (e) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Falha ao buscar os itens recarregue a página!",
			});
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (!isMobile) {
			onSearchClose();
		}
	});

	useEffect(() => {
		obterDados();

		return () => {
			setOpcoes([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const menu = (
		<Menu onClick={onClick}>
			{opcoes?.map((opcao) => (
				<Menu.Item key={opcao.unidade.idunidade}>
					{opcao.unidade.nome}
					<Link to={`${APP_PREFIX_PATH}/inteligencia`}></Link>
				</Menu.Item>
			))}
		</Menu>
	);

	useEffect(() => {
		const dataVencimento = dadosMenu?.faturas?.data_vencimento;
		const dataBloqueioAtual = dadosMenu?.faturas?.data_possivel_bloqueio;

		if (dataVencimento) {
			const dataVencimentoMoment = moment(dataVencimento, "YYYY-MM-DD");
			const dataAtual = moment();
			const dias = dataVencimentoMoment.diff(dataAtual, "days") + 1;
			setTempoFaltando(dias);
			setDataVencimento(dataVencimentoMoment.format("DD/MM/YYYY"));
			const dataBloqueioOk = dataBloqueioAtual
				? moment(dataBloqueioAtual).format("DD/MM/YYYY")
				: dataVencimentoMoment.add(30, "days").format("DD/MM/YYYY");
			setDataBloqueio(dataBloqueioOk);
		}
	}, [dadosMenu?.faturas]);

	const getBackgroundColor = () => {
		if (tempoFaltando <= 8 && tempoFaltando > 2) return "#fff2bd";
		if (tempoFaltando <= 2 && tempoFaltando > 0) return "#ffc000";
		if (tempoFaltando === 0) return "#ff3d3d";
		if (tempoFaltando < 0 && tempoFaltando > -2) return "#fff2bd";
		if (tempoFaltando < -2) return "#ff3d3d";
	};

	const getTextColor = () => {
		return tempoFaltando === 0 || tempoFaltando < -2
			? "#eef1f6"
			: "#455560";
	};

	const getMessage = () => {
		if (tempoFaltando <= 8 && tempoFaltando > 2)
			return `Faltam ${tempoFaltando} dias para o vencimento! ${dataVencimento}`;
		if (tempoFaltando <= 2 && tempoFaltando > 0)
			return tempoFaltando > 1
				? `Faltam ${tempoFaltando} dias para o vencimento! ${dataVencimento}`
				: `Falta ${tempoFaltando} dia para o vencimento! ${dataVencimento}`;
		if (tempoFaltando === 0)
			return `A fatura vence hoje! ${dataVencimento}`;
		if (tempoFaltando < 0 && tempoFaltando > -2)
			return "Aguardando o pagamento!";
		if (tempoFaltando < -2)
			return `A última fatura venceu em ${dataVencimento} e o sistema será bloqueado em ${dataBloqueio}!`;
	};

	const getWidth = () => {
		return tempoFaltando < -2 ? "600px" : "400px";
	};

	return (
		<Header
			className={`app-header ${navMode}`}
			style={{ backgroundColor: headerNavColor }}
		>
			<div
				className={`app-header-wrapper ${
					isNavTop ? "layout-top-nav" : ""
				}`}
			>
				<Logo logoType={navMode} />
				<div
					className="nav"
					style={{
						width: `calc(100% - ${getNavWidth()})`,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<div className="nav-left">
						<ul className="ant-menu ant-menu-root ant-menu-horizontal">
							{isNavTop && !isMobile ? null : (
								<li
									className="ant-menu-item ant-menu-item-only-child"
									onClick={() => {
										onToggle();
									}}
								>
									{navCollapsed || isMobile ? (
										<MenuUnfoldOutlined className="nav-icon" />
									) : (
										<MenuFoldOutlined className="nav-icon" />
									)}
								</li>
							)}
						</ul>
					</div>
					<TemPermissao permissao={Permissoes.FATURAS.VISUALIZAR}>
						{tempoFaltando <= 8 && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								<div
									onClick={() => {
										history.push(
											`${CAIXA_PREFIX_PATH}/faturas`,
										);
									}}
									style={{
										backgroundColor: getBackgroundColor(),
										fontWeight: "450",
										color: getTextColor(),
										display: "flex",
										borderRadius: 20,
										width: getWidth(),
										height: "30px",
										alignItems: "center",
										justifyContent: "center",
										cursor: "pointer",
									}}
								>
									<div>{getMessage()}</div>
								</div>
							</div>
						)}
					</TemPermissao>

					<div className="nav-right">
						<Dropdown overlay={menu} trigger={["click"]}>
							<div
								style={{
									display: "flex",
									width: 150,
									cursor: "pointer",
								}}
							>
								<div
									style={{
										flexGrow: 1,
										flexBasis: 0,
										textAlign: "right",
									}}
								>
									<span
										style={{
											color: "white",
											marginRight: 10,
										}}
										onClick={(e) => e.preventDefault()}
									>
										{user.unidade.nome}
									</span>
								</div>
								<div>
									<span style={{ color: "white" }}>
										<DownOutlined />
									</span>
								</div>
							</div>
						</Dropdown>

						{user.franqueador_acess && !franqueadora && (
							<ModalController
								title="Acessar Franqueadora"
								renderButton={(params) => (
									<Button
										ghost
										{...params}
										style={{
											position: "relative",
											marginLeft: 10,
										}}
									>
										Acessar Franqueadora
									</Button>
								)}
								renderForm={(params) => (
									<FranqueadoraSenhaModal {...params} />
								)}
							/>
						)}
						{franqueadora && (
							<Button
								ghost
								style={{
									position: "relative",
									top: 5,
									marginLeft: 10,
								}}
								onClick={() => {
									localStorage.removeItem(
										"auth_token_franqueadora",
									);
									history.push(
										`${APP_PREFIX_PATH}/inteligencia`,
									);
								}}
							>
								Voltar para o sistema
							</Button>
						)}
						<ModalController
							renderButton={(params) => (
								<Tooltip
									placement="bottom"
									title={"Ver minhas metas!"}
								>
									<Menu mode="horizontal" {...params}>
										<Menu.Item key="0">
											<StockOutlined
												style={{
													fontSize: 20,
													marginLeft: 10,
													marginRight: -5,
												}}
											/>
										</Menu.Item>
									</Menu>
								</Tooltip>
							)}
							renderForm={(params) => (
								<MetasUsuario {...params} />
							)}
							width={1100}
						/>
						<NavNotification />
						<NavProfile />
						{/* <NavPanel direction={direction} /> Aqui que tem as configurações de tela, se quiser testar */}
					</div>
					<NavSearch active={searchActive} close={onSearchClose} />
				</div>
			</div>
		</Header>
	);
};

const mapStateToProps = ({ theme, user }) => {
	const {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
	} = theme;
	return {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
		user: { ...user },
	};
};

export default connect(mapStateToProps, {
	toggleCollapsedNav,
	onMobileNavToggle,
})(HeaderNav);
