import { io } from "socket.io-client";
import { env } from "../configs/EnvironmentConfig";

const chatUrl = env.API_SOCKET_ENDPOINT_URL + "chat";

export const chatSocket = io(chatUrl, {
	transports: ["websocket"],
	secure: env.API_SOCKET_ENDPOINT_URL.includes("https"),
	path: env.API_SOCKET_SUFIX_PATH,
});
