import { useCallback } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Badge } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
	isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
	let keyList = [];
	let keyString = "";
	if (key) {
		const arr = key.split("-");
		for (let index = 0; index < arr.length; index++) {
			const elm = arr[index];
			index === 0
				? (keyString = elm)
				: (keyString = `${keyString}-${elm}`);
			keyList.push(keyString);
		}
	}
	return keyList;
};

const SideNavContent = (props) => {
	const {
		navigationConfig,
		sideNavTheme,
		routeInfo,
		hideGroupTitle,
		localization,
		onMobileNavToggle,
		listaPermissoes,
		notificacoesMenu,
	} = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");

	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false);
		}
	};

	/*
    Verifica se esse submenu tem pelo menos um filho com permissão.
    Se não tiver, retorna false, para que o submenu em si não apareça sem filhos
  */
	const verificarPermissoesSubmenu = (listaItens) => {
		for (const item of listaItens) {
			let temPermissao = verificarPermissoes(item.permissao);

			if (item.submenu && item.submenu?.length > 0) {
				temPermissao =
					temPermissao && verificarPermissoesSubmenu(item.submenu);
			}

			if (temPermissao) {
				return true;
			}
		}

		return false;
	};

	/*
      Verificar se tem a permissão ou lista de permissões passadas
      por parâmetro
    */
	const verificarPermissoes = useCallback(
		(permissoes) => {
			if (permissoes === undefined || permissoes === null) return true;

			if (!Array.isArray(permissoes)) permissoes = [permissoes];

			// Fica true caso tenha todas permissões
			for (const p of permissoes) {
				const temPermissao = !p || listaPermissoes[p];

				if (temPermissao) {
					return true;
				}
			}

			return false;
		},
		[listaPermissoes],
	);

	const getSubItens = (item) => {
		return item.submenu.map((subMenuFirst) =>
			subMenuFirst.submenu.length > 0 ? (
				<SubMenu
					icon={
						subMenuFirst.icon ? (
							<Icon type={subMenuFirst?.icon} />
						) : null
					}
					key={subMenuFirst.key}
					title={setLocale(localization, subMenuFirst.title)}
					style={{
						display:
							verificarPermissoes(subMenuFirst.permissao) &&
							verificarPermissoesSubmenu(subMenuFirst.submenu)
								? "block"
								: "none",
					}}
				>
					{menuItemFactory(subMenuFirst)}
				</SubMenu>
			) : (
				menuItemFactory(subMenuFirst)
			),
		);
	};

	const menuItemFactory = (item) => {
		return item.submenu.length > 0 ? (
			item.showTitle ? (
				<Menu.ItemGroup
					key={item.key}
					title={setLocale(localization, item.title)}
					style={{
						display:
							verificarPermissoes(item.permissao) &&
							verificarPermissoesSubmenu(item.submenu)
								? "block"
								: "none",
					}}
				>
					{getSubItens(
						item,
						localization,
						closeMobileNav,
						verificarPermissoes,
						verificarPermissoesSubmenu,
					)}
				</Menu.ItemGroup>
			) : (
				getSubItens(
					item,
					localization,
					closeMobileNav,
					verificarPermissoes,
					verificarPermissoesSubmenu,
				)
			)
		) : (
			<Menu.Item
				key={item.key}
				style={{
					display: verificarPermissoes(item.permissao)
						? "block"
						: "none",
				}}
			>
				{item.icon ? <Icon type={item?.icon} /> : null}
				{item.indexNotificacoes ? (
					<Badge
						style={{ marginTop: 5 }}
						count={notificacoesMenu[item.indexNotificacoes]}
						offset={[18]}
					>
						{setLocale(localization, item?.title)}
					</Badge>
				) : (
					<span>{setLocale(localization, item?.title)}</span>
				)}
				{item.path ? (
					<Link onClick={() => closeMobileNav()} to={item.path} />
				) : null}
			</Menu.Item>
		);
	};

	return (
		<Menu
			theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
			mode="inline"
			style={{ height: "100%", borderRight: 0 }}
			defaultSelectedKeys={[routeInfo?.key]}
			defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
			className={hideGroupTitle ? "hide-group-title" : ""}
		>
			{navigationConfig.map((item) => menuItemFactory(item))}
		</Menu>
	);
};

const TopNavContent = (props) => {
	const { topNavColor, localization, navigationConfig } = props;
	return (
		<Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
			{navigationConfig.map((menu) =>
				menu.submenu.length > 0 ? (
					<SubMenu
						key={menu.key}
						popupClassName="top-nav-menu"
						title={
							<span>
								{menu.icon ? <Icon type={menu?.icon} /> : null}
								<span>
									{setLocale(localization, menu.title)}
								</span>
							</span>
						}
					>
						{menu.submenu.map((subMenuFirst) =>
							subMenuFirst.submenu.length > 0 ? (
								<SubMenu
									key={subMenuFirst.key}
									icon={
										subMenuFirst.icon ? (
											<Icon type={subMenuFirst?.icon} />
										) : null
									}
									title={setLocale(
										localization,
										subMenuFirst.title,
									)}
								>
									{subMenuFirst.submenu.map(
										(subMenuSecond) => (
											<Menu.Item key={subMenuSecond.key}>
												<span>
													{setLocale(
														localization,
														subMenuSecond.title,
													)}
												</span>
												<Link to={subMenuSecond.path} />
											</Menu.Item>
										),
									)}
								</SubMenu>
							) : (
								<Menu.Item key={subMenuFirst.key}>
									{subMenuFirst.icon ? (
										<Icon type={subMenuFirst?.icon} />
									) : null}
									<span>
										{setLocale(
											localization,
											subMenuFirst.title,
										)}
									</span>
									<Link to={subMenuFirst.path} />
								</Menu.Item>
							),
						)}
					</SubMenu>
				) : (
					<Menu.Item key={menu.key}>
						{menu.icon ? <Icon type={menu?.icon} /> : null}
						<span>{setLocale(localization, menu?.title)}</span>
						{menu.path ? <Link to={menu.path} /> : null}
					</Menu.Item>
				),
			)}
		</Menu>
	);
};

const MenuContent = (props) => {
	return props.type === NAV_TYPE_SIDE ? (
		<SideNavContent {...props} />
	) : (
		<TopNavContent {...props} />
	);
};

const mapStateToProps = ({ theme, permissoes, menu }) => {
	const { sideNavTheme, topNavColor } = theme;
	return {
		sideNavTheme,
		topNavColor,
		listaPermissoes: permissoes,
		notificacoesMenu: menu,
	};
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
