import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import Views from "./views";
import { HashRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import menuService from "services/MenuService";
import JwtAuthService from "services/JwtAuthService";

const themes = {
	dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
	light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
	const [loaded, setLoaded] = useState(false);
	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await menuService.populateMenuData();
			}
		} catch (e) {}
		setLoaded(true);
	};

	useEffect(() => {
		populateMenu();
	}, []);

	return (
		<div className="App">
			<Provider store={store}>
				<ThemeSwitcherProvider
					themeMap={themes}
					defaultTheme={THEME_CONFIG.currentTheme}
					insertionPoint="styles-insertion-point"
				>
					<Router>
						<Switch>
							{loaded && <Route path="/" component={Views} />}
						</Switch>
					</Router>
				</ThemeSwitcherProvider>
			</Provider>
		</div>
	);
}

export default App;
